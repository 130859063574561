const Oils=[
    {imgsrc:"img/i5.avif",
        alt:"image",
        name:"MUSTARD OIL",
        dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats.",
        packsize:"Available: 2 Ltr.",
        item:"/mustard1",
        categ:"grounded",
        id:1
        },{imgsrc:"img/i11.avif",
            alt:"image",
            name:"MUSTARD OIL",
            dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats.",
            packsize:"Available: 5 Ltr.",
            categ:"grounded",
            item:"/mustard5",
        
            id:2
            },
            {imgsrc:"img/i12.avif",
            alt:"image",
            name:"MUSTARD OIL",
            dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats.",
            packsize:"Available: 500ml. | 1 Ltr.",
            categ:"grounded",
            item:"/mustard500",
        
            id:3
            },
            {imgsrc:"img/i15.avif",
                alt:"image",
                name:"SOYA REFINED OIL",
                dec:"Hiven cooking oil ensure that, the safe and pure soya oil has  nutri lock action which keeps you fit everyday.It also makes your bone stronger and heart healthier.It is also rich in Omega 3 which keeps the heart healthy",     
ingredients:"Soya oil is known for its multiple health benefits as it has the right balance of omega 3 which help  to maintaining normal cholesterol levels.",
                packsize:"Available: 500ml.|1Ltr.|2Ltr.|5Ltr.",
                categ:"grounded",
                item:"/refined",
                id:4
                },
]
const Refinedo=[
    {imgsrc:"img/i15.avif",
    alt:"image",
    name:"SOYA REFINED OIL",
dec:"Hiven cooking oil ensure that, the safe and pure soya oil has  nutri lock action which keeps you fit everyday.It also makes your bone stronger and heart healthier.It is also rich in Omega 3 which keeps the heart healthy",     packsize:"Available: 500ml.|1Ltr.|2Ltr.|5Ltr.",
ingredients:"Soya oil is known for its multiple health benefits as it has the right balance of omega 3 which help  to maintaining normal cholesterol levels.",
hindi:"हिवेन कुकिंग ऑयल यह सुनिश्चित करता है कि सुरक्षित और शुद्ध सोया तेल में न्यूट्री लॉक एक्शन है जो आपको हर रोज फिट रखता है। यह आपकी हड्डी को मजबूत और दिल को स्वस्थ बनाता है। यह ओमेगा 3 से भी भरपूर है जो दिल को स्वस्थ रखता है| सोया तेल अपने अनेक स्वास्थ्य लाभों के लिए जाना जाता है क्योंकि इसमें ओमेगा 3 का सही संतुलन होता है जो सामान्य कोलेस्ट्रॉल के स्तर को बनाए रखने में मदद करता है।",
hindipack:"उपलब्ध: 500ml.|1Ltr.|2Ltr.|5Ltr.",
    categ:"grounded",
    id:1
}]
const Mustard5l=[
    {imgsrc:"img/i11.avif",
    alt:"image",
    name:"MUSTARD OIL",
    dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats."
,hindi:"भारत में इसे सरसों का तेल के नाम से जाना जाता है और यह हर रसोई में पाया जाने वाला एक प्रमुख घटक है। सरसों के तेल में एक मजबूत स्वाद होता है और यह कई व्यंजनों का स्वाद बढ़ाता है। सरसों का तेल लाल-भूरे या एम्बर रंग का होता है और इसकी तेज़ गंध और तीखे स्वाद के लिए जाना जाता है। सरसों के तेल का तीखापन एलिल आइसोथियोसाइनेट की उपस्थिति के कारण होता है। यह वसायुक्त वनस्पति तेल सरसों के बीजों को दबाकर प्राप्त किया जाता है।सरसों के तेल में लगभग 60% मोनोअनसैचुरेटेड फैटी एसिड (MUFA) (42% इरुसिक एसिड और 12% ओलिक एसिड) होता है; इसमें लगभग 21% पॉलीअनसेचुरेटेड वसा (PUFA) (6% ओमेगा-3 अल्फा-लिनोलेनिक एसिड (ALA) और 15% ओमेगा-6 लिनोलिक एसिड (LA)) होता है और इसमें लगभग 12% संतृप्त वसा होती है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
    packsize:"उपलब्ध: 5 लीटर.",
    categ:"grounded",
    id:1
    }]
    const Mustard500ml=[
    {imgsrc:"img/i12.avif",
    alt:"image",
    name:"MUSTARD OIL",
    dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats."
,hindi:"भारत में इसे सरसों का तेल के नाम से जाना जाता है और यह हर रसोई में पाया जाने वाला एक प्रमुख घटक है। सरसों के तेल में एक मजबूत स्वाद होता है और यह कई व्यंजनों का स्वाद बढ़ाता है। सरसों का तेल लाल-भूरे या एम्बर रंग का होता है और इसकी तेज़ गंध और तीखे स्वाद के लिए जाना जाता है। सरसों के तेल का तीखापन एलिल आइसोथियोसाइनेट की उपस्थिति के कारण होता है। यह वसायुक्त वनस्पति तेल सरसों के बीजों को दबाकर प्राप्त किया जाता है।सरसों के तेल में लगभग 60% मोनोअनसैचुरेटेड फैटी एसिड (MUFA) (42% इरुसिक एसिड और 12% ओलिक एसिड) होता है; इसमें लगभग 21% पॉलीअनसेचुरेटेड वसा (PUFA) (6% ओमेगा-3 अल्फा-लिनोलेनिक एसिड (ALA) और 15% ओमेगा-6 लिनोलिक एसिड (LA)) होता है और इसमें लगभग 12% संतृप्त वसा होती है।",
hindipack:"उपलब्ध: 500ml. | 1 लीटर.",
    packsize:"Available: 500ml. | 1 Ltr.",
    categ:"grounded",
    id:1
}]
const Mustard1l=[
    {imgsrc:"img/i5.avif",
    alt:"image",
    name:"MUSTARD OIL",
    dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats."
,hindi:"भारत में इसे सरसों का तेल के नाम से जाना जाता है और यह हर रसोई में पाया जाने वाला एक प्रमुख घटक है। सरसों के तेल में एक मजबूत स्वाद होता है और यह कई व्यंजनों का स्वाद बढ़ाता है। सरसों का तेल लाल-भूरे या एम्बर रंग का होता है और इसकी तेज़ गंध और तीखे स्वाद के लिए जाना जाता है। सरसों के तेल का तीखापन एलिल आइसोथियोसाइनेट की उपस्थिति के कारण होता है। यह वसायुक्त वनस्पति तेल सरसों के बीजों को दबाकर प्राप्त किया जाता है।सरसों के तेल में लगभग 60% मोनोअनसैचुरेटेड फैटी एसिड (MUFA) (42% इरुसिक एसिड और 12% ओलिक एसिड) होता है; इसमें लगभग 21% पॉलीअनसेचुरेटेड वसा (PUFA) (6% ओमेगा-3 अल्फा-लिनोलेनिक एसिड (ALA) और 15% ओमेगा-6 लिनोलिक एसिड (LA)) होता है और इसमें लगभग 12% संतृप्त वसा होती है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
    packsize:"उपलब्ध: 2 लीटर.",
    categ:"grounded",
    id:1
    }]


export {Oils,Mustard1l,Mustard500ml,Mustard5l,Refinedo}