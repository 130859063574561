import React from 'react'
import {Mustard5l} from "../prod/ProliOils";
import Container from '../Container'

function val(n){
  return(
<Container
    imgsrc={n.imgsrc}
    alt={n.alt}
    key={n.id}
    desc={n.dec}
    categ={n.categ}
    pck={n.packsize}
    name={n.name}
    ing={n.ingredients}
    hindi={n.hindi}
    hindipack={n.hindipack}
    />);}
function Mustard5() {
  
    return (
        <>
        <h1 className='text-center my-2'>MUSTARD 5 Ltr OIL</h1>
        <div className="mainpro">
             {Mustard5l.map(val)}
          </div>
        </>
      )
}

export default Mustard5