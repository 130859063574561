import React from 'react'
function Aboutus() {
  return (
    <div className='box-design my-2'>
     <h1 className='text-center'>
       Welcome to Hiven Masala
      </h1>
      <p>
      Hiven Products, Established in the year 2024 by Mr. Satyendra Sharma, "Hiven Masala" brand has become a symbolic name in U.P. India. The Indian Subcontinent we have been successful, in being the leading manufacturers and suppliers of all kinds of basic and formulated spices. Produced out of finest ingredients, the company's high quality food spices include: Turmeric Powder,Red Chili Powder, Meat Masala, Paneer Masala, Garam Masala , Sabji Masala ,Chhole Masala , Dhania Powder ,  Chana Besan ,Masala Chips ,Mustard 1Lt. , Mustard 5Lt., Mustard 500ml ,Navrang Namkeen , Refined Oil , Salted Chips , Tea . </p>
      
<p>
The secret of our success lies in our infrastructural set-up and dedicated team members. With all the latest facilities and state-of-the-art equipment's, our company is conveniently handling bulk production along with consistent quality management.
</p>

<p>At Hiven Product, we believe in the power of quality ingredients. Our mission is to provide our customers with the best products that nature has to offer. With a focus on sustainability and ethical sourcing, we ensure that every item in our store is of the highest standard. Join us on a journey of flavor and wellness.</p>
  
<h2 className='text-center'>Ownership & Management Team</h2>
<p>
Satyendra Sharma is self-motivated and resourceful managing director with a proven ability to develop and strengthen management teams in order to maximise company profitability and efficiency. Experienced leading and growing all sectors of a business to make it a dynamic and progressive organisation. Possessing excellent communication skills and able to establish sustainable and profitable relationships with customers, suppliers and stakeholders across the world. Now looking for a new and challenging managerial position, one which will make best use of my existing skills and experience and also further my personal and professional development.
</p>
<h2 className='text-center'>
Quality Assurance
</h2>
<p>
To maintain highest standards of quality of the spices, we make sure quality ingredients are used in the preparation process. These ingredients are thoroughly tested on purity, quality and rich taste by the quality controllers.

</p>
<p>

<h1 className='text-center'>Our Mission </h1>
For us at Hiven, 'spice' is more than just a word.
It's an emotion that revives exquisite feasts & aromas, festive recipes and tempting garnishes.
Being a pioneer in spices, quality is of the utmost essence at Hiven. It's our passion to enrich lives with our spices and complement culinary memories. Hiven is India’s No.1 spice brand that is driven to season your precious moments with your families & deliver great taste!....
Our mission is to provide hygienic and best quality product to our valued customer. We are aiming to extend our business operations to Meat Masala and Hing range along with spice production. We want to offer best product in multiple niches making overall food experience better. Inaugurate Hiven Masala spices as the leading brand, helping chefs feeling empowered to get the perfect taste in food while working tirelessly to bring aroma and flavor consistently right.
</p>
<p>

 <h1 className='text-center'>Our Core Values </h1>
<ol className='list-group-numbered'>
  <li>Consumer Satisfactory</li>
  <li>Continuous Improvement</li>
  <li>Quality ingredients</li>
  <li>Integrity</li>
  <li>Offline grocery store</li>
  <li>Through Planning & Execution</li>
  <li>sustainable sourcing</li>
</ol>
Our belief and your trust will be the beginning of a happy union that would be so mutually profitable and satisfying for both of us. May both of us soar high and attain heights as never before!
</p>
    </div>
  )
}

export default Aboutus