const Spices = [
  {
    imgsrc: "img/i6.avif",
    alt: "image",
    name: "MEAT MASALA",
    dec: "Hiven Meat Masala adds an exotic taste as well as aroma to varying types of meat prepartions and excellent in quality. Our Meat Masala is a blend of various ingredients.Cooking Instructions:3 Easy Step Recipe:Step 1: TO Cook 500gms of meat cut 150gms onion, grind 30 gm garlic 20 gm ginger and make a paste with 15 g Hiven Meat Masala.Step 2: Fry onion with 70g oil till it turns golden brown. Than mix Hiven Meat Masala and ginger garlic paste and fry it upto 10 minutes.Step 3: After that mix the meat and fry it again for 15 minutes, then add salt and water as desired and let it cook 5-10 minutes in cooker.",
    ingredients:
      "Turmeric Powder, Coriander Powder, chilli Powder, Black pepper Powder, Cardamom, Cumin seed, Dry Ginger, Cassia, Onion Powder, Garlic, Nutmeg, cloves, Mace, Poppy seeds.",
    packsize: "Available: 50gm | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",

    categ: "grounded",
    item: "/meatmasala",
    id: 1,
  },
  {
    imgsrc: "img/i7.avif",
    alt: "image",
    name: "SABJI MASALA",
    dec: " Hiven sabji Masala is recognized by clients owing to its freshness, aroma and taste. We offer this in varied sized packing to meet the demands of our valued clients. Our product range is also examined on set parameters to ensure its purity and effectiveness. Blend made of Coriander, Onion Flakes & Hing to add special taste and colour to any dry sabji.",
    ingredients:"Sabji Masala is a versatile spice blend used in various vegetable dishes across Indian cuisine. Made with a combination of whole and powdered spices, including coriander, cumin, cloves, and more, this masala adds depth and richness to curries, stir-fries, and gravies.",
    packsize: "Available: 50gm | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",
    categ: "grounded",
    item: "/sabjimasala",
    id: 2,
  },
  {
    imgsrc: "img/i8.avif",
    alt: "image",
    name: "CHHOLE MASALA",
    dec: "Chole Masala is a popular Punjabi dish where chickpeas are simmered in a spicy & tangy gravy. 'Chole' is a Punjabi word for 'chickpeas' and 'masala' is a word for 'spices'. So this dish is nothing but chickpeas cooked to perfection with various spices, onions, tomatoes and herbs.",
    ingredients:
      "Chickpea is a legume that is relished all over India. While the term Chole is specifically used to mention the Punjabi dish. Ingredients like anardana (dried pomegranate seeds), amchur (dried mango powder), ajwain (carom seeds), dried red chilies and black cardamoms are the key ingredients in this Chole Masala.",
    packsize: "Available: 50gm | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",
    categ: "grounded",
    item: "/chhole",
    id: 3,
  },

  {
    imgsrc: "img/i10.avif",
    alt: "image",
    name: "PANNER MASALA",
    dec: "Hiven  Paneer Masala is the right masala for making Paneer and very popular for its rich taste.Hiven Paneer Masala has acquired the status of a foremost vegetarian recipe. Paneer Masala adds unique flavor and aroma to the dish",
    ingredients:
      "Dishes you can cook are kadai paneer, Palak Paneer, Matar Paneer and Shahi Paneer.",
    packsize: "Available: 50gm | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",
    categ: "grounded",
    item: "/pannermasala",
    id: 4,
  },

  {
    imgsrc: "img/i13.avif",
    alt: "image",
    name: "RED CHILLI POWDER",
    dec: "The freshest of red chillies, crushed in a powder form adds hot taste, giving curry a mouthwatering flavour. Just use Red Chilly powder to get an instant aroma that your neighbours will envy. The selected Indian Red Chillie's powder make dishes hot colourful & hygenic with huge medicinal properties.",
    packsize: "Available: 50gm | 100gm.",
    categ: "grounded",
    item: "/redchilli",
    id: 5,
  },
  {
    imgsrc: "img/i14.avif",
    alt: "image",
    name: "GARAM MASALA",
    dec: " Garam masala is an Indian spice blend. Garam means 'hot' while masala means 'spices', but it's not necessarily hot and spicy - the name refers to the warm flavors of its ingredients, such as cinnamon and cumin.",
    ingredients:
      "Garam masala is better when made with whole spices that have been roasted and ground, but this is a quick and easy substitute that's pretty good.Store the garam masala in an airtight container in a cool, dry, dark place (such as a pantry or spice cabinet) for up to six months",
    packsize: "Available: 50gm. | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",

    categ: "grounded",
    item: "/garammasala",
    id: 6,
  },

  {
    imgsrc: "img/i16.avif",
    alt: "image",
    name: "DHANIA POWDER",
    dec: "Usage: Key ingredient to thick Indian Curries & add color to it.",
    ingredients:
      "Ingredients:this is made under strict hygenic conditions. Raw Masale are selected from best of the crops and sundried to add more relish and natural flavour to your cooking. No artificial colouring has been used.",
    packsize: "Available: 50gm | 100gm.",
    categ: "grounded",
    item: "/dhaniapowder",
    id: 7,
  },

  {
    imgsrc: "img/i3.avif",
    alt: "image",
    name: "TURMERIC POWDER",
    dec: "Finely ground Turmeric powder is basically used to add color & flavor to the food. It also adds medicinal value to Indan Cusine.Usage: Turmeric powder is the most frequently used Indian spice in cooking salt.Hiven Turmeric powder is a blend of fresh, pure quality aroma and lively natural colour. It adds unmatched taste nd texture to your cooking.- No artifical colour.- Processed under careful supervision and strict hygenic conditions. ",
    ingredients:
      "Hiven Turmeric powder are made under strict hygenic conditions. Raw Masale are selected from best of the crops and sundried to add more relish and natural flavour to your cooking. No artificial colouring has been used.",
    categ: "grounded",
    packsize: "Available: 50gm. | 100gm.",
    item: "/turmericpowder",
    id: 8,
  },
  {
    imgsrc: "img/i21.avif",
    alt: "image",
    name: "PANNER MASALA POUCH",
    dec: "Hiven  Paneer Masala is the right masala for making Paneer and very popular for its rich taste.Hiven Paneer Masala has acquired the status of a foremost vegetarian recipe. Paneer Masala adds unique flavor and aroma to the dish",
    ingredients:
      "Dishes you can cook are kadai paneer, Palak Paneer, Matar Paneer and Shahi Paneer.",
    packsize: "Available: 10gm | 100gm.",
    categ: "grounded",
    item: "/pannermasala",
    id: 9,
  },
  {
    imgsrc: "img/i20.avif",
    alt: "image",
    name: "SABJI MASALA POUCH",
    dec: " Hiven sabji Masala is recognized by clients owing to its freshness, aroma and taste. We offer this in varied sized packing to meet the demands of our valued clients. Our product range is also examined on set parameters to ensure its purity and effectiveness. Blend made of Coriander, Onion Flakes & Hing to add special taste and colour to any dry sabji.",
    ingredients:"Sabji Masala is a versatile spice blend used in various vegetable dishes across Indian cuisine. Made with a combination of whole and powdered spices, including coriander, cumin, cloves, and more, this masala adds depth and richness to curries, stir-fries, and gravies.",
    packsize: "Available: 10gm | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",
    categ: "grounded",
    item: "/sabjimasala",
    id: 10,
  },
  {
    imgsrc: "img/i23.avif",
    alt: "image",
    name: "GARAM MASALA POUCH",
    dec: " Garam masala is an Indian spice blend. Garam means 'hot' while masala means 'spices', but it's not necessarily hot and spicy - the name refers to the warm flavors of its ingredients, such as cinnamon and cumin.",
    ingredients:
      "Garam masala is better when made with whole spices that have been roasted and ground, but this is a quick and easy substitute that's pretty good.Store the garam masala in an airtight container in a cool, dry, dark place (such as a pantry or spice cabinet) for up to six months",
    packsize: "Available: 10gm | 100gm",
    pouch: "Available Pouch: 10gm | 100gm",

    categ: "grounded",
    item: "/garammasala",
    id: 11,
  },
  {
    imgsrc: "img/i24.avif",
    alt: "image",
    name: "CHHOLE MASALA POUCH",
    dec: "Chole Masala is a popular Punjabi dish where chickpeas are simmered in a spicy & tangy gravy. 'Chole' is a Punjabi word for 'chickpeas' and 'masala' is a word for 'spices'. So this dish is nothing but chickpeas cooked to perfection with various spices, onions, tomatoes and herbs.",
    ingredients:
      "Chickpea is a legume that is relished all over India. While the term Chole is specifically used to mention the Punjabi dish. Ingredients like anardana (dried pomegranate seeds), amchur (dried mango powder), ajwain (carom seeds), dried red chilies and black cardamoms are the key ingredients in this Chole Masala.",
    packsize: "Available: 10gm | 100gm",
    categ: "grounded",
    item: "/chhole",
    id: 12,
  },

  {
    imgsrc: "img/i22.avif",
    alt: "image",
    name: "MEAT MASALA POUCH",
    dec: "Hiven Meat Masala adds an exotic taste as well as aroma to varying types of meat prepartions and excellent in quality. Our Meat Masala is a blend of various ingredients.Cooking Instructions:3 Easy Step Recipe:Step 1: TO Cook 500gms of meat cut 150gms onion, grind 30 gm garlic 20 gm ginger and make a paste with 15 g Hiven Meat Masala.Step 2: Fry onion with 70g oil till it turns golden brown. Than mix Hiven Meat Masala and ginger garlic paste and fry it upto 10 minutes.Step 3: After that mix the meat and fry it again for 15 minutes, then add salt and water as desired and let it cook 5-10 minutes in cooker.",
    ingredients:
      "Turmeric Powder, Coriander Powder, chilli Powder, Black pepper Powder, Cardamom, Cumin seed, Dry Ginger, Cassia, Onion Powder, Garlic, Nutmeg, cloves, Mace, Poppy seeds.",
    packsize: "Available: 10gm | 100gm",
    categ: "grounded",
    item: "/meatmasala",
    id: 13,
  },
];
const RedChili = [
  {
    imgsrc: "img/i13.avif",
    alt: "image",
    name: "RED CHILLI POWDER",
    dec: "The freshest of red chillies, crushed in a powder form adds hot taste, giving curry a mouthwatering flavour. Just use Red Chilly powder to get an instant aroma that your neighbours will envy. The selected Indian Red Chillie's powder make dishes hot colourful & hygenic with huge medicinal properties.",
    packsize: "Available: 50gm. | 100gm.",
    hindi:"सबसे ताज़ी लाल मिर्च को पाउडर के रूप में पीसकर करी को तीखा स्वाद दिया जाता है, जिससे करी का स्वाद मुंह में पानी लाने वाला हो जाता है। बस लाल मिर्च पाउडर का इस्तेमाल करें और तुरंत ऐसी खुशबू पाएं जिससे आपके पड़ोसी भी ईर्ष्या करेंगे। चुनिंदा भारतीय लाल मिर्च का पाउडर व्यंजनों को तीखा, रंगीन और स्वास्थ्यवर्धक बनाता है और इसमें औषधीय गुण भी भरपूर मात्रा में होते हैं।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
    categ: "grounded",
    id:1,
  },
];
const Garamm = [
  {
    imgsrc: "img/i14.avif",
    alt: "image",
    name: "GARAM MASALA",
    dec: " Garam masala is an Indian spice blend. Garam means 'hot' while masala means 'spices', but it's not necessarily hot and spicy - the name refers to the warm flavors of its ingredients, such as cinnamon and cumin.",
    ingredients:
      "Garam masala is better when made with whole spices that have been roasted and ground, but this is a quick and easy substitute that's pretty good.Store the garam masala in an airtight container in a cool, dry, dark place (such as a pantry or spice cabinet) for up to six months",
    packsize: "Available: 50gm | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",
    hindi:"गरम मसाला एक भारतीय मसाला मिश्रण है। गरम का मतलब है 'गर्म' जबकि मसाला का मतलब है 'मसाले', लेकिन यह जरूरी नहीं कि तीखा और मसालेदार हो - यह नाम इसके अवयवों के गर्म स्वाद को दर्शाता है, जैसे कि दालचीनी और जीरा। गरम मसाला तब बेहतर होता है जब इसे भुने और पीसे हुए साबुत मसालों से बनाया जाता है, लेकिन यह एक त्वरित और आसान विकल्प है जो काफी अच्छा है। गरम मसाले को एक एयरटाइट कंटेनर में एक ठंडी, सूखी, अंधेरी जगह (जैसे कि पेंट्री या मसाला कैबिनेट) में छह महीने तक स्टोर करें।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
hindipackpouch:"उपलब्ध पाउच: 10 ग्राम | 100 ग्राम",
    categ: "grounded",
    id:1,
  },
];

const Dhaniap = [
  {
    imgsrc: "img/i16.avif",
    alt: "image",
    name: "DHANIA POWDER",
    dec: "Usage: Key ingredient to thick Indian Curries & add color to it.",
    ingredients:
      "Ingredients:this is made under strict hygenic conditions. Raw Masale are selected from best of the crops and sundried to add more relish and natural flavour to your cooking. No artificial colouring has been used.",
    packsize: "Available: 50gm. | 100gm.",
    hindi:"उपयोग: गाढ़ी भारतीय करी बनाने का मुख्य घटक और उसमें रंग जोड़ने के लिए। सामग्री: इसे सख्त स्वच्छता स्थितियों के तहत बनाया गया है। कच्चे मसाले सबसे अच्छी फसलों से चुने जाते हैं और आपके खाना पकाने में अधिक स्वाद और प्राकृतिक स्वाद जोड़ने के लिए धूप में सुखाए जाते हैं। किसी भी कृत्रिम रंग का उपयोग नहीं किया गया है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
    categ: "grounded",
    id:1,
  },
];
const Paneerm = [
  {
    imgsrc: "img/i10.avif",
    alt: "image",
    name: "PANNER MASALA",
    dec: "Hiven  Paneer Masala is the right masala for making Paneer and very popular for its rich taste.Hiven Paneer Masala has acquired the status of a foremost vegetarian recipe. Paneer Masala adds unique flavor and aroma to the dish",
    ingredients:
      "Dishes you can cook are kadai paneer, Palak Paneer, Matar Paneer and Shahi Paneer.",
    packsize: "Available: 50gm. | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",
    hindi:"हिवेन पनीर मसाला पनीर बनाने के लिए एकदम सही मसाला है और अपने बेहतरीन स्वाद के लिए बहुत लोकप्रिय है। हिवेन पनीर मसाला ने एक बेहतरीन शाकाहारी रेसिपी का दर्जा हासिल कर लिया है। पनीर मसाला डिश में अनोखा स्वाद और खुशबू जोड़ता है| आप जो व्यंजन बना सकते हैं वे हैं कढ़ाई पनीर, पालक पनीर, मटर पनीर और शाही पनीर।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
hindipackpouch:"उपलब्ध पाउच: 10 ग्राम | 100 ग्राम",
    categ: "grounded",
    id:1,
  },
];
const Meatm = [
  {
    imgsrc: "img/i6.avif",
    alt: "image",
    name: "MEAT MASALA",
    dec: "Hiven Meat Masala adds an exotic taste as well as aroma to varying types of meat prepartions and excellent in quality. Our Meat Masala is a blend of various ingredients.Cooking Instructions:3 Easy Step Recipe:Step 1: TO Cook 500gms of meat cut 150gms onion, grind 30 gm garlic 20 gm ginger and make a paste with 15 g Hiven Meat Masala.Step 2: Fry onion with 70g oil till it turns golden brown. Than mix Hiven Meat Masala and ginger garlic paste and fry it upto 10 minutes.Step 3: After that mix the meat and fry it again for 15 minutes, then add salt and water as desired and let it cook 5-10 minutes in cooker.",
    packsize: "Available: 50gm | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",
    hindi:"हिवेन मीट मसाला विभिन्न प्रकार के मीट तैयारियों में एक अनोखा स्वाद और सुगंध जोड़ता है और इसकी गुणवत्ता भी बेहतरीन है। हमारा मीट मसाला विभिन्न सामग्रियों का मिश्रण है। खाना पकाने के निर्देश: 3 आसान स्टेप रेसिपी: चरण 1: 500 ग्राम मीट पकाने के लिए 150 ग्राम प्याज काटें, 30 ग्राम लहसुन 20 ग्राम अदरक पीसें और 15 ग्राम हिवेन मीट मसाला के साथ पेस्ट बनाएं। चरण 2: प्याज को 70 ग्राम तेल में सुनहरा भूरा होने तक भूनें। फिर हिवेन मीट मसाला और अदरक लहसुन का पेस्ट मिलाएं और इसे 10 मिनट तक भूनें। चरण 3: उसके बाद मीट को मिलाएं और इसे फिर से 15 मिनट तक भूनें, फिर नमक और पानी डालें और इसे कुकर में 5-10 मिनट तक पकने दें।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
hindipackpouch:"उपलब्ध पाउच: 10 ग्राम | 100 ग्राम",
    categ: "grounded",
    id:1,
  },
];
const Sabjim = [
  {
    imgsrc: "img/i7.avif",
    alt: "image",
    name: "SABJI MASALA",
    dec: " Hiven sabji Masala is recognized by clients owing to its freshness, aroma and taste. We offer this in varied sized packing to meet the demands of our valued clients. Our product range is also examined on set parameters to ensure its purity and effectiveness. Blend made of Coriander, Onion Flakes & Hing to add special taste and colour to any dry sabji.",
    ingredients:"Sabji Masala is a versatile spice blend used in various vegetable dishes across Indian cuisine. Made with a combination of whole and powdered spices, including coriander, cumin, cloves, and more, this masala adds depth and richness to curries, stir-fries, and gravies.",
    packsize: "Available: 50gm. | 100gm.",
    pouch: "Available: 10gm | 100gm",
    hindi:"हिवेन सब्जी मसाला ग्राहकों द्वारा इसकी ताज़गी, सुगंध और स्वाद के कारण पहचाना जाता है। हम अपने मूल्यवान ग्राहकों की मांगों को पूरा करने के लिए इसे विभिन्न आकार की पैकिंग में पेश करते हैं। हमारी उत्पाद श्रृंखला की शुद्धता और प्रभावशीलता सुनिश्चित करने के लिए निर्धारित मापदंडों पर भी जाँच की जाती है। किसी भी सूखी सब्जी में विशेष स्वाद और रंग जोड़ने के लिए धनिया, प्याज के गुच्छे और हींग से बना मिश्रण । सब्ज़ी मसाला एक बहुमुखी मसाला मिश्रण है जिसका इस्तेमाल भारतीय व्यंजनों में विभिन्न सब्ज़ियों के व्यंजनों में किया जाता है। साबुत और पाउडर मसालों के मिश्रण से बना यह मसाला, जिसमें धनिया, जीरा, लौंग और बहुत कुछ शामिल है, करी, स्टर-फ्राई और ग्रेवी में गहराई और समृद्धि जोड़ता है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
hindipackpouch:"उपलब्ध पाउच: 10 ग्राम | 100 ग्राम",
    categ: "grounded",
    id:1,
  },
];
const Chole = [
  {
    imgsrc: "img/i8.avif",
    alt: "image",
    name: "CHHOLE MASALA",
    dec: "Chole Masala is a popular Punjabi dish where chickpeas are simmered in a spicy & tangy gravy. 'Chole' is a Punjabi word for 'chickpeas' and 'masala' is a word for 'spices'. So this dish is nothing but chickpeas cooked to perfection with various spices, onions, tomatoes and herbs.",
    ingredients:
      "Chickpea is a legume that is relished all over India. While the term Chole is specifically used to mention the Punjabi dish. Ingredients like anardana (dried pomegranate seeds), amchur (dried mango powder), ajwain (carom seeds), dried red chilies and black cardamoms are the key ingredients in this Chole Masala.",
    packsize: "Available: 50gm. | 100gm.",
    pouch: "Available Pouch: 10gm | 100gm",
    hindi:"छोले मसाला एक लोकप्रिय पंजाबी व्यंजन है जिसमें छोले को मसालेदार और तीखी ग्रेवी में पकाया जाता है। छोले पंजाबी शब्द है जिसका मतलब है 'छोले' और 'मसाला' शब्द है जिसका मतलब है 'मसालों'। तो यह व्यंजन कुछ और नहीं बल्कि छोले हैं जिन्हें विभिन्न मसालों, प्याज़, टमाटर और जड़ी-बूटियों के साथ बेहतरीन तरीके से पकाया जाता है। चना एक ऐसी फली है जिसे पूरे भारत में बहुत पसंद किया जाता है। जबकि छोले शब्द का इस्तेमाल खास तौर पर पंजाबी व्यंजन के लिए किया जाता है। अनारदाना (सूखे अनार के बीज), आमचूर (सूखे आम का पाउडर), अजवाइन, सूखी लाल मिर्च और काली इलायची जैसी सामग्री इस छोले मसाले में मुख्य सामग्री हैं।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
hindipackpouch:"उपलब्ध पाउच: 10 ग्राम | 100 ग्राम",
    categ: "grounded",
    id:1,
  },
];
const Turmericp = [
  {
    imgsrc: "img/i3.avif",
    alt: "image",
    name: "TURMERIC POWDER",
    dec: "Finely ground Turmeric powder is basically used to add color & flavor to the food. It also adds medicinal value to Indan Cusine.Usage: Turmeric powder is the most frequently used Indian spice in cooking salt.Hiven Turmeric powder is a blend of fresh, pure quality aroma and lively natural colour. It adds unmatched taste nd texture to your cooking.- No artifical colour.- Processed under careful supervision and strict hygenic conditions. ",
    ingredients:
      "Hiven Turmeric powder are made under strict hygenic conditions. Raw Masale are selected from best of the crops and sundried to add more relish and natural flavour to your cooking. No artificial colouring has been used.",
    packsize: "Available: 50gm. | 100gm.",
    hindi:"बारीक पिसी हुई हल्दी पाउडर का उपयोग मुख्य रूप से भोजन में रंग और स्वाद जोड़ने के लिए किया जाता है। यह भारतीय व्यंजनों में औषधीय गुण भी जोड़ता है। उपयोग: हल्दी पाउडर खाना पकाने के नमक में सबसे अधिक इस्तेमाल किया जाने वाला भारतीय मसाला है। हिवेन हल्दी पाउडर ताजा, शुद्ध गुणवत्ता वाली सुगंध और जीवंत प्राकृतिक रंग का मिश्रण है। यह आपके खाना पकाने में बेजोड़ स्वाद और बनावट जोड़ता है। - कोई कृत्रिम रंग नहीं। - सावधानीपूर्वक पर्यवेक्षण और सख्त स्वच्छता स्थितियों के तहत संसाधित। हिवेन हल्दी पाउडर सख्त स्वच्छता स्थितियों के तहत बनाया जाता है। कच्चे मसाले सबसे अच्छी फसलों से चुने जाते हैं और आपके खाना पकाने में अधिक स्वाद और प्राकृतिक स्वाद जोड़ने के लिए धूप में सुखाए जाते हैं। किसी भी कृत्रिम रंग का उपयोग नहीं किया गया है।",
    hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",    
    categ: "grounded",
    id:1,
  },
];

export {
  Spices,
  Turmericp,
  Paneerm,
  Meatm,
  Chole,
  Dhaniap,
  Sabjim,
  Garamm,
  RedChili,
};
