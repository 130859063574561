const Grocery=[
    {imgsrc:"img/i9.avif",
        alt:"image",
        name:"CHANA BESAN",
        dec:"Just Organic emphasises on producing nutritive food through sustainable processes. In this stride, Chana Besan  is free from harsh additives, is a non GMO vegan product, environmentally friendly and is made by grinding, drying and de-husking de-cuticled split brown chickpea. It is a complement to cereal-based diets rich in protein, especially for the poor and developing countries where people are vegetarians or are unable to afford animal protein. The pulse proteins are lysine-rich and have low amino acid-containing sulphur.",
        packsize:"Available: 50gm | 100gm.",
        categ:"grounded",
        item:"/chanabesan",
        id:1
        }, 
        {imgsrc:"img/i17.avif",
            alt:"image",
            name:"TEA",
            dec:"Hiven Tea – Blended by expert crafters, brings you the signature flavour of Purani Dilli Ki Mithai Chai, inspired by Mithai Chai served in the streets of Delhi with tasting notes of Pista, Cardamom and Condensed Milk.",
            packsize:"Available: 50gm. | 100gm.",
            categ:"grounded",
            item:"/tea",
            id:2
            }, 
]
const ChaanaBesan=[
    {imgsrc:"img/i9.avif",
    alt:"image",
    name:"CHANA BESAN",
    dec:"Just Organic emphasises on producing nutritive food through sustainable processes. In this stride, Chana Besan  is free from harsh additives, is a non GMO vegan product, environmentally friendly and is made by grinding, drying and de-husking de-cuticled split brown chickpea. It is a complement to cereal-based diets rich in protein, especially for the poor and developing countries where people are vegetarians or are unable to afford animal protein. The pulse proteins are lysine-rich and have low amino acid-containing sulphur.",
    packsize:"Available: 50gm | 100gm.",
    hindi:"जस्ट ऑर्गेनिक संधारणीय प्रक्रियाओं के माध्यम से पौष्टिक भोजन के उत्पादन पर जोर देता है। इस दिशा में, चना बेसन कठोर योजकों से मुक्त है, एक गैर जीएमओ शाकाहारी उत्पाद है, पर्यावरण के अनुकूल है और इसे भूरे रंग के छोले को पीसकर, सुखाकर और छीलकर बनाया जाता है। यह प्रोटीन से भरपूर अनाज आधारित आहार का पूरक है, खासकर गरीब और विकासशील देशों के लिए जहां लोग शाकाहारी हैं या पशु प्रोटीन खरीदने में असमर्थ हैं। दालों के प्रोटीन लाइसिन से भरपूर होते हैं और इनमें अमीनो एसिड युक्त सल्फर कम होता है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
    categ:"grounded",
    id:1
    }]

const Teaa=[
    {imgsrc:"img/i17.avif",
    alt:"image",
    name:"TEA",
    dec:"Hiven Tea – Blended by expert crafters, brings : the signature flavour of Purani Dilli Ki Mithai Chai, inspired by Mithai Chai served in the streets of Delhi with tasting notes of Pista, Cardamom and Condensed Milk.",
    packsize:"Available: 50gm | 100gm.",
    hindi:"हिवेन चाय - विशेषज्ञ कारीगरों द्वारा मिश्रित, लाता है पुरानी दिल्ली की मिठाई चाय का विशिष्ट स्वाद, जो दिल्ली की गलियों में पिस्ता, इलायची और गाढ़े दूध के स्वाद के साथ परोसी जाने वाली मिठाई चाय से प्रेरित है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
    categ:"grounded",
    id:1
}]

export {Grocery,ChaanaBesan,Teaa}