const Snacks=[
    {imgsrc:"img/i2.avif",
        alt:"image",
        name:"CHIPS",
        categ:"grounded",
        item:"/saltedchips",
        packsize:"Available: 50gm | 100gm.",
        dec:"It all starts with farm-grown potatoes, cooked and seasoned to perfection.Processed from fine quality potatoes, this snack goes best with all kinds of beverages, cold drinks, hot brews or sweet fruit juices.",
        ingredients:"Potato, edible vegetable oil, milk solids, spices and condiments (tamarind powder, onion powder, cumin powder, black pepper powder, garlic powder, turmeric powder, large cardamom powder, ginger powder), salt, black salt.",
        id:1},
        {imgsrc:"img/i1.avif",
            alt:"image",
            name:"CHIPS",
            dec:"Ingredients: Potatoes, Vegetable Oil (Palm), Spices and Condiments (Iodised salt, Maltodextrin, Black salt, Onion powder, Mango powder, Garlic powder, Tomato powder), Sugar, Acidity regulators (E330, E296, E334), Flavours (Onion and Garlic), Anticaking agent (E551), Color (E160c)..",
            packsize:"Available: 50gm | 100gm.",
            categ:"grounded",
            item:"/masalachip",
            id:2},
        {imgsrc:"img/i4.avif",
        alt:"image",
        name:"NAVRANG NAMKEEN",
        dec:"Navrang Namkeen has a authentic mouth watering taste. This mixture is the blend of gram flour noodles, peanuts, lentils, gram pulse, spices and condiments which make it a tasty mixture. This special mixture is to tasty and crunchy that you can’t stop eating at all day long.",
        packsize:"Available: 50gm | 100gm.",
        categ:"grounded",
        item:"/navrang",
        id:3
        },
]   
 const Navrangg=[
    {imgsrc:"img/i4.avif",
    alt:"image",
    name:"NAVRANG NAMKIN",
    dec:"Navrang Namkeen has a authentic mouth watering taste. This mixture is the blend of gram flour noodles, peanuts, lentils, gram pulse, spices and condiments which make it a tasty mixture. This special mixture is to tasty and crunchy that you can’t stop eating at all day long.",
    packsize:"Available: 50gm | 100gm.",
    hindi:"नवरंग नमकीन का स्वाद मुंह में पानी लाने वाला है। यह मिश्रण बेसन नूडल्स, मूंगफली, दाल, चने की दाल, मसालों और मसालों का मिश्रण है जो इसे एक स्वादिष्ट मिश्रण बनाता है। यह खास मिश्रण इतना स्वादिष्ट और कुरकुरा है कि आप इसे दिन भर खाना बंद नहीं कर पाएंगे।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
    categ:"grounded",
    id:1
    }]
  
    const Chip =
    [
    {imgsrc:"img/i2.avif",
    alt:"image",
    name:"CHIPS",
    dec:"It all starts with farm-grown potatoes, cooked and seasoned to perfection.Processed from fine quality potatoes, this snack goes best with all kinds of beverages, cold drinks, hot brews or sweet fruit juices.",
        ingredients:"Potato, edible vegetable oil, milk solids, spices and condiments (tamarind powder, onion powder, cumin powder, black pepper powder, garlic powder, turmeric powder, large cardamom powder, ginger powder), salt, black salt.",
        hindi:"इसकी शुरुआत खेत में उगाए गए आलू से होती है, जिन्हें पूरी तरह पकाया और मसालेदार बनाया जाता है। उच्च गुणवत्ता वाले आलू से तैयार यह नाश्ता सभी प्रकार के पेय पदार्थों, कोल्ड ड्रिंक्स, गर्म पेय या मीठे फलों के रस के साथ सबसे अच्छा लगता है।आलू, खाद्य वनस्पति तेल, दूध के ठोस पदार्थ, मसाले और मसाला (इमली पाउडर, प्याज पाउडर, जीरा पाउडर, काली मिर्च पाउडर, लहसुन पाउडर, हल्दी पाउडर, बड़ी इलायची पाउडर, अदरक पाउडर), नमक, काला नमक।",
        hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
            packsize:"Available: 50gm. | 100gm.",
    categ:"grounded",
    id:1}]
    const MasalaChip =
    [
    {imgsrc:"img/i1.avif",
    alt:"image",
    name:"CHIPS",
    dec:"Ingredients: Potatoes, Vegetable Oil (Palm), Spices and Condiments (Iodised salt, Maltodextrin, Black salt, Onion powder, Mango powder, Garlic powder, Tomato powder), Sugar, Acidity regulators (E330, E296, E334), Flavours (Onion and Garlic), Anticaking agent (E551), Color (E160c).",
    packsize:"Available: 50gm. | 100gm.",
    hindi:"सामग्री: आलू, वनस्पति तेल (ताड़), मसाले और मसालों (आयोडीन युक्त नमक, माल्टोडेक्सट्रिन, काला नमक, प्याज पाउडर, आम पाउडर, लहसुन पाउडर, टमाटर पाउडर), चीनी, अम्लता नियामक (E330, E296, E334), स्वाद (प्याज और लहसुन), एंटीकेकिंग एजेंट (E551), रंग (E160c)।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
    categ:"grounded",
    id:1}]


export {Snacks,MasalaChip,Chip,Navrangg}