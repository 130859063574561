const Ground=[
{imgsrc:"img/i2.avif",
alt:"image",
name:"SaltedCHIPS",
dec:"It all starts with farm-grown potatoes, cooked and seasoned to perfection.Processed from fine quality potatoes, this snack goes best with all kinds of beverages, cold drinks, hot brews or sweet fruit juices.",
        ingredients:"Potato, edible vegetable oil, milk solids, spices and condiments (tamarind powder, onion powder, cumin powder, black pepper powder, garlic powder, turmeric powder, large cardamom powder, ginger powder), salt, black salt.",
packsize:"Available: 50gm | 100gm.",
hindi:"इसकी शुरुआत खेत में उगाए गए आलू से होती है, जिन्हें पूरी तरह पकाया और मसालेदार बनाया जाता है। उच्च गुणवत्ता वाले आलू से तैयार यह नाश्ता सभी प्रकार के पेय पदार्थों, कोल्ड ड्रिंक्स, गर्म पेय या मीठे फलों के रस के साथ सबसे अच्छा लगता है।आलू, खाद्य वनस्पति तेल, दूध के ठोस पदार्थ, मसाले और मसाला (इमली पाउडर, प्याज पाउडर, जीरा पाउडर, काली मिर्च पाउडर, लहसुन पाउडर, हल्दी पाउडर, बड़ी इलायची पाउडर, अदरक पाउडर), नमक, काला नमक।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
categ:"grounded",
item:"/saltedchips",
id:1},
{imgsrc:"img/i1.avif",
    alt:"image",
    name:"Masala CHIPS",
    dec:"Ingredients: Potatoes, Vegetable Oil (Palm), Spices and Condiments (Iodised salt, Maltodextrin, Black salt, Onion powder, Mango powder, Garlic powder, Tomato powder), Sugar, Acidity regulators (E330, E296, E334), Flavours (Onion and Garlic), Anticaking agent (E551), Color (E160c)..",
    packsize:"Available: 50gm | 100gm.",
    categ:"grounded",
    hindi:"सामग्री: आलू, वनस्पति तेल (ताड़), मसाले और मसालों (आयोडीन युक्त नमक, माल्टोडेक्सट्रिन, काला नमक, प्याज पाउडर, आम पाउडर, लहसुन पाउडर, टमाटर पाउडर), चीनी, अम्लता नियामक (E330, E296, E334), स्वाद (प्याज और लहसुन), एंटीकेकिंग एजेंट (E551), रंग (E160c)।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
  
item:"/masalachip",
    id:2},
{imgsrc:"img/i4.avif",
alt:"image",
name:"NAVRANG NAMKEEN",
dec:"Navrang Namkeen has a authentic mouth watering taste. This mixture is the blend of gram flour noodles, peanuts, lentils, gram pulse, spices and condiments which make it a tasty mixture. This special mixture is to tasty and crunchy that you can’t stop eating at all day long.",
packsize:"Available: 50gm | 100gm.",
hindi:"नवरंग नमकीन का स्वाद मुंह में पानी लाने वाला है। यह मिश्रण बेसन नूडल्स, मूंगफली, दाल, चने की दाल, मसालों और मसालों का मिश्रण है जो इसे एक स्वादिष्ट मिश्रण बनाता है। यह खास मिश्रण इतना स्वादिष्ट और कुरकुरा है कि आप इसे दिन भर खाना बंद नहीं कर पाएंगे।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
categ:"grounded",
item:"/navrang",
id:3
},
{imgsrc:"img/i9.avif",
    alt:"image",
    name:"CHANA BESAN",
    dec:"Just Organic emphasises on producing nutritive food through sustainable processes. In this stride, Chana Besan  is free from harsh additives, is a non GMO vegan product, environmentally friendly and is made by grinding, drying and de-husking de-cuticled split brown chickpea. It is a complement to cereal-based diets rich in protein, especially for the poor and developing countries where people are vegetarians or are unable to afford animal protein. The pulse proteins are lysine-rich and have low amino acid-containing sulphur.",
    packsize:"Available: 50gm | 100gm.",
    categ:"grounded",
   hindi:"जस्ट ऑर्गेनिक संधारणीय प्रक्रियाओं के माध्यम से पौष्टिक भोजन के उत्पादन पर जोर देता है। इस दिशा में, चना बेसन कठोर योजकों से मुक्त है, एक गैर जीएमओ शाकाहारी उत्पाद है, पर्यावरण के अनुकूल है और इसे भूरे रंग के छोले को पीसकर, सुखाकर और छीलकर बनाया जाता है। यह प्रोटीन से भरपूर अनाज आधारित आहार का पूरक है, खासकर गरीब और विकासशील देशों के लिए जहां लोग शाकाहारी हैं या पशु प्रोटीन खरीदने में असमर्थ हैं। दालों के प्रोटीन लाइसिन से भरपूर होते हैं और इनमें अमीनो एसिड युक्त सल्फर कम होता है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
    item:"/chanabesan",
    id:4
    }, 
{imgsrc:"img/i5.avif",
alt:"image",
name:"MUSTARD OIL ",
dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats."
,
packsize:"Available: 2 Ltr.",
hindi:"भारत में इसे सरसों का तेल के नाम से जाना जाता है और यह हर रसोई में पाया जाने वाला एक प्रमुख घटक है। सरसों के तेल में एक मजबूत स्वाद होता है और यह कई व्यंजनों का स्वाद बढ़ाता है। सरसों का तेल लाल-भूरे या एम्बर रंग का होता है और इसकी तेज़ गंध और तीखे स्वाद के लिए जाना जाता है। सरसों के तेल का तीखापन एलिल आइसोथियोसाइनेट की उपस्थिति के कारण होता है। यह वसायुक्त वनस्पति तेल सरसों के बीजों को दबाकर प्राप्त किया जाता है।सरसों के तेल में लगभग 60% मोनोअनसैचुरेटेड फैटी एसिड (MUFA) (42% इरुसिक एसिड और 12% ओलिक एसिड) होता है; इसमें लगभग 21% पॉलीअनसेचुरेटेड वसा (PUFA) (6% ओमेगा-3 अल्फा-लिनोलेनिक एसिड (ALA) और 15% ओमेगा-6 लिनोलिक एसिड (LA)) होता है और इसमें लगभग 12% संतृप्त वसा होती है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
item:"/mustard1",
categ:"grounded",
id:5
},{imgsrc:"img/i11.avif",
    alt:"image",
    name:"MUSTARD OIL ",
    dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats.",
    packsize:"Available: 5 Ltr.",
    categ:"grounded",
   hindi:"भारत में इसे सरसों का तेल के नाम से जाना जाता है और यह हर रसोई में पाया जाने वाला एक प्रमुख घटक है। सरसों के तेल में एक मजबूत स्वाद होता है और यह कई व्यंजनों का स्वाद बढ़ाता है। सरसों का तेल लाल-भूरे या एम्बर रंग का होता है और इसकी तेज़ गंध और तीखे स्वाद के लिए जाना जाता है। सरसों के तेल का तीखापन एलिल आइसोथियोसाइनेट की उपस्थिति के कारण होता है। यह वसायुक्त वनस्पति तेल सरसों के बीजों को दबाकर प्राप्त किया जाता है। सरसों के तेल में लगभग 60% मोनोअनसैचुरेटेड फैटी एसिड (MUFA) (42% इरुसिक एसिड और 12% ओलिक एसिड) होता है; इसमें लगभग 21% पॉलीअनसेचुरेटेड वसा (PUFA) (6% ओमेगा-3 अल्फा-लिनोलेनिक एसिड (ALA) और 15% ओमेगा-6 लिनोलिक एसिड (LA)) होता है और इसमें लगभग 12% संतृप्त वसा होती है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
    item:"/mustard5",

    id:6
    },
    {imgsrc:"img/i12.avif",
    alt:"image",
    name:"MUSTARD OIL ",
    dec:"It is popularly known as Sarson ka Tel in India and is a key ingredient found in every kitchen. Mustard oil has a strong flavour and enhances the taste of several dishes.Mustard oil is reddish-brown or amber in colour and is known for its strong smell and pungent sharp flavour. The pungency of mustard oil is due to the presence of allyl isothiocyanate. This fatty vegetable oil is obtained by pressing mustard seeds. ",
ingredients:"Mustard oil has about 60% monounsaturated fatty acids(MUFA)(42% erucic acid and 12% oleic acid); it has about 21% polyunsaturated fats(PUFA) (6% the omega-3 alpha-linolenic acid (ALA) and 15% omega-6 linoleic acid(LA)) and it has about 12% saturated fats.",
    packsize:"Available: 500ml. | 1 Ltr.",
    categ:"grounded",
   hindi:"भारत में इसे सरसों का तेल के नाम से जाना जाता है और यह हर रसोई में पाया जाने वाला एक प्रमुख घटक है। सरसों के तेल में एक मजबूत स्वाद होता है और यह कई व्यंजनों का स्वाद बढ़ाता है। सरसों का तेल लाल-भूरे या एम्बर रंग का होता है और इसकी तेज़ गंध और तीखे स्वाद के लिए जाना जाता है। सरसों के तेल का तीखापन एलिल आइसोथियोसाइनेट की उपस्थिति के कारण होता है। यह वसायुक्त वनस्पति तेल सरसों के बीजों को दबाकर प्राप्त किया जाता है। सरसों के तेल में लगभग 60% मोनोअनसैचुरेटेड फैटी एसिड (MUFA) (42% इरुसिक एसिड और 12% ओलिक एसिड) होता है; इसमें लगभग 21% पॉलीअनसेचुरेटेड वसा (PUFA) (6% ओमेगा-3 अल्फा-लिनोलेनिक एसिड (ALA) और 15% ओमेगा-6 लिनोलिक एसिड (LA)) होता है और इसमें लगभग 12% संतृप्त वसा होती है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
    item:"/mustard500",

    id:7
    },
    {imgsrc:"img/i15.avif",
        alt:"image",
        name:"SOYA REFINED OIL",
        dec:"Hiven cooking oil ensure that, the safe and pure soya oil has  nutri lock action which keeps you fit everyday.It also makes your bone stronger and heart healthier.It is also rich in Omega 3 which keeps the heart healthy",
ingredients:"Soya oil is known for its multiple health benefits as it has the right balance of omega 3 which help  to maintaining normal cholesterol levels.",
        packsize:"Available: 500ml.|1Ltr.|2Ltr.|5Ltr.",
        categ:"grounded",
       hindi:"हिवेन कुकिंग ऑयल यह सुनिश्चित करता है कि सुरक्षित और शुद्ध सोया तेल में न्यूट्री लॉक एक्शन है जो आपको हर रोज फिट रखता है। यह आपकी हड्डी को मजबूत और दिल को स्वस्थ बनाता है। यह ओमेगा 3 से भी भरपूर है जो दिल को स्वस्थ रखता है| सोया तेल अपने अनेक स्वास्थ्य लाभों के लिए जाना जाता है क्योंकि इसमें ओमेगा 3 का सही संतुलन होता है जो सामान्य कोलेस्ट्रॉल के स्तर को बनाए रखने में मदद करता है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
        item:"/refined",
        id:8
        },
{imgsrc:"img/i17.avif",
    alt:"image",
    name:"TEA",
    dec:"Hiven Tea – Blended by expert crafters, brings you the signature flavour of Purani Dilli Ki Mithai Chai, inspired by Mithai Chai served in the streets of Delhi with tasting notes of Pista, Cardamom and Condensed Milk.",
    packsize:"Available: 50gm. | 100gm.",
    categ:"grounded",
   hindi:"हिवेन चाय - विशेषज्ञ कारीगरों द्वारा मिश्रित, लाता है पुरानी दिल्ली की मिठाई चाय का विशिष्ट स्वाद, जो दिल्ली की गलियों में पिस्ता, इलायची और गाढ़े दूध के स्वाद के साथ परोसी जाने वाली मिठाई चाय से प्रेरित है।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम",
    item:"/tea",
    id:9
    },  
{imgsrc:"img/i6.avif",
alt:"image",
name:"MEAT MASALA",
dec: "Hiven Meat Masala adds an exotic taste as well as aroma to varying types of meat prepartions and excellent in quality. Our Meat Masala is a blend of various ingredients.Cooking Instructions:3 Easy Step Recipe:Step 1: TO Cook 500gms of meat cut 150gms onion, grind 30 gm garlic 20 gm ginger and make a paste with 15 g Hiven Meat Masala.Step 2: Fry onion with 70g oil till it turns golden brown. Than mix Hiven Meat Masala and ginger garlic paste and fry it upto 10 minutes.Step 3: After that mix the meat and fry it again for 15 minutes, then add salt and water as desired and let it cook 5-10 minutes in cooker.",
    ingredients:
      "Turmeric Powder, Coriander Powder, chilli Powder, Black pepper Powder, Cardamom, Cumin seed, Dry Ginger, Cassia, Onion Powder, Garlic, Nutmeg, cloves, Mace, Poppy seeds.",
packsize:"Available: 50gm | 100gm.",
pouch:"Available Pouch: 10gm | 100gm",
categ:"grounded",
item:"/meatmasala",
id:10
},
{imgsrc:"img/i7.avif",
alt:"image",
name:"SABJI MASALA",
dec: " Hiven sabji Masala is recognized by clients owing to its freshness, aroma and taste. We offer this in varied sized packing to meet the demands of our valued clients. Our product range is also examined on set parameters to ensure its purity and effectiveness. Blend made of Coriander, Onion Flakes & Hing to add special taste and colour to any dry sabji.",
ingredients:"Sabji Masala is a versatile spice blend used in various vegetable dishes across Indian cuisine. Made with a combination of whole and powdered spices, including coriander, cumin, cloves, and more, this masala adds depth and richness to curries, stir-fries, and gravies.",
packsize:"Available: 50gm | 100gm.",
pouch:"Available Pouch: 10gm | 100gm",

categ:"grounded",
item:"/sabjimasala",
id:11
},
{imgsrc:"img/i8.avif",
alt:"image",
name:"CHHOLE MASALA",
dec: "Chole Masala is a popular Punjabi dish where chickpeas are simmered in a spicy & tangy gravy. 'Chole' is a Punjabi word for 'chickpeas' and 'masala' is a word for 'spices'. So this dish is nothing but chickpeas cooked to perfection with various spices, onions, tomatoes and herbs.",
ingredients:
  "Chickpea is a legume that is relished all over India. While the term Chole is specifically used to mention the Punjabi dish. Ingredients like anardana (dried pomegranate seeds), amchur (dried mango powder), ajwain (carom seeds), dried red chilies and black cardamoms are the key ingredients in this Chole Masala.",
packsize:"Available: 50gm | 100gm.",
pouch:"Available Pouch: 10gm | 100gm",
categ:"grounded",
item:"/chhole",
id:12
},

{imgsrc:"img/i10.avif",
alt:"image",
name:"PANNER MASALA",
dec:"Hiven  Paneer Masala is the right masala for making Paneer and very popular for its rich taste.Hiven Paneer Masala has acquired the status of a foremost vegetarian recipe. Paneer Masala adds unique flavor and aroma to the dish",
ingredients:"Dishes you can cook are kadai paneer, Palak Paneer, Matar Paneer and Shahi Paneer.",
packsize:"Available: 50gm | 100gm.",
pouch:"Available Pouch: 10gm | 100gm",
categ:"grounded",
item:"/pannermasala",
id:13
},

{imgsrc:"img/i13.avif",
alt:"image",
name:"RED CHILLI POWDER",
dec:"The freshest of red chillies, crushed in a powder form adds hot taste, giving curry a mouthwatering flavour. Just use Red Chilly powder to get an instant aroma that your neighbours will envy. The selected Indian Red Chillie's powder make dishes hot colourful & hygenic with huge medicinal properties.",
packsize:"Available: 50gm | 100gm.",
hindi:"सबसे ताज़ी लाल मिर्च को पाउडर के रूप में पीसकर करी को तीखा स्वाद दिया जाता है, जिससे करी का स्वाद मुंह में पानी लाने वाला हो जाता है। बस लाल मिर्च पाउडर का इस्तेमाल करें और तुरंत ऐसी खुशबू पाएं जिससे आपके पड़ोसी भी ईर्ष्या करेंगे। चुनिंदा भारतीय लाल मिर्च का पाउडर व्यंजनों को तीखा, रंगीन और स्वास्थ्यवर्धक बनाता है और इसमें औषधीय गुण भी भरपूर मात्रा में होते हैं।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
categ:"grounded",
item:"/redchilli",
id:14
},
{imgsrc:"img/i14.avif",
alt:"image",
name:"GARAM MASALA",
dec: " Garam masala is an Indian spice blend. Garam means 'hot' while masala means 'spices', but it's not necessarily hot and spicy - the name refers to the warm flavors of its ingredients, such as cinnamon and cumin.",
ingredients:
  "Garam masala is better when made with whole spices that have been roasted and ground, but this is a quick and easy substitute that's pretty good.Store the garam masala in an airtight container in a cool, dry, dark place (such as a pantry or spice cabinet) for up to six months",
packsize:"Available: 50gm. | 100gm.",
pouch:"Available Pouch: 10gm | 100gm",
categ:"grounded",
item:"/garammasala",
id:15
},

{imgsrc:"img/i16.avif",
alt:"image",
name:"DHANIA POWDER",
dec: "Usage: Key ingredient to thick Indian Curries & add color to it.",
    ingredients:
      "Ingredients:this is made under strict hygenic conditions. Raw Masale are selected from best of the crops and sundried to add more relish and natural flavour to your cooking. No artificial colouring has been used.",
packsize:"Available: 50gm | 100gm.",
categ:"grounded",
item:"/dhaniapowder",
id:16
},

{imgsrc:"img/i3.avif",
    alt:"image",
    name:"TURMERIC POWDER",
    dec: "Finely ground Turmeric powder is basically used to add color & flavor to the food. It also adds medicinal value to Indan Cusine.Usage: Turmeric powder is the most frequently used Indian spice in cooking salt.Hiven Turmeric powder is a blend of fresh, pure quality aroma and lively natural colour. It adds unmatched taste nd texture to your cooking.- No artifical colour.- Processed under careful supervision and strict hygenic conditions. ",
    ingredients:
      "Hiven Turmeric powder are made under strict hygenic conditions. Raw Masale are selected from best of the crops and sundried to add more relish and natural flavour to your cooking. No artificial colouring has been used.",
    categ:"grounded",
    packsize:"Available: 50gm. | 100gm.",
  
item:"/turmericpowder",
    id:17
    }
    ,    
{imgsrc:"img/i21.avif",
    alt:"image",
    name:"PANNER MASALA POUCH",
    dec:"Hiven  Paneer Masala is the right masala for making Paneer and very popular for its rich taste.Hiven Paneer Masala has acquired the status of a foremost vegetarian recipe. Paneer Masala adds unique flavor and aroma to the dish",
    ingredients:"Dishes you can cook are kadai paneer, Palak Paneer, Matar Paneer and Shahi Paneer.",
    packsize:"Available: 10gm | 100gm.",
    categ:"grounded",
    hindi:"हिवेन पनीर मसाला पनीर बनाने के लिए एकदम सही मसाला है और अपने बेहतरीन स्वाद के लिए बहुत लोकप्रिय है। हिवेन पनीर मसाला ने एक बेहतरीन शाकाहारी रेसिपी का दर्जा हासिल कर लिया है। पनीर मसाला डिश में अनोखा स्वाद और खुशबू जोड़ता है| आप जो व्यंजन बना सकते हैं वे हैं कढ़ाई पनीर, पालक पनीर, मटर पनीर और शाही पनीर।",
    hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
    hindipackpouch:"उपलब्ध पाउच: 10 ग्राम | 100 ग्राम",  
item:"/pannermasala",
    id:18
    },
{imgsrc:"img/i20.avif",
alt:"image",
name:"SABJI MASALA POUCH",
dec: " Hiven sabji Masala is recognized by clients owing to its freshness, aroma and taste. We offer this in varied sized packing to meet the demands of our valued clients. Our product range is also examined on set parameters to ensure its purity and effectiveness. Blend made of Coriander, Onion Flakes & Hing to add special taste and colour to any dry sabji.",
ingredients:"Sabji Masala is a versatile spice blend used in various vegetable dishes across Indian cuisine. Made with a combination of whole and powdered spices, including coriander, cumin, cloves, and more, this masala adds depth and richness to curries, stir-fries, and gravies.",
packsize:"Available: 10gm | 100gm.",
pouch:"Available Pouch: 10gm | 100gm",
categ:"grounded",
hindi:"गरम मसाला एक भारतीय मसाला मिश्रण है। गरम का मतलब है 'गर्म' जबकि मसाला का मतलब है 'मसाले', लेकिन यह जरूरी नहीं कि तीखा और मसालेदार हो - यह नाम इसके अवयवों के गर्म स्वाद को दर्शाता है, जैसे कि दालचीनी और जीरा। गरम मसाला तब बेहतर होता है जब इसे भुने और पीसे हुए साबुत मसालों से बनाया जाता है, लेकिन यह एक त्वरित और आसान विकल्प है जो काफी अच्छा है। गरम मसाले को एक एयरटाइट कंटेनर में एक ठंडी, सूखी, अंधेरी जगह (जैसे कि पेंट्री या मसाला कैबिनेट) में छह महीने तक स्टोर करें।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
item:"/sabjimasala",
id:19
},
{imgsrc:"img/i23.avif",
alt:"image",
name:"GARAM MASALA POUCH",
dec: " Garam masala is an Indian spice blend. Garam means 'hot' while masala means 'spices', but it's not necessarily hot and spicy - the name refers to the warm flavors of its ingredients, such as cinnamon and cumin.",
ingredients:
  "Garam masala is better when made with whole spices that have been roasted and ground, but this is a quick and easy substitute that's pretty good.Store the garam masala in an airtight container in a cool, dry, dark place (such as a pantry or spice cabinet) for up to six months",
packsize:"Available: 10gm | 100gm",
pouch:"Available Pouch: 10gm | 100gm",

categ:"grounded",
hindi:"गरम मसाला एक भारतीय मसाला मिश्रण है। गरम का मतलब है 'गर्म' जबकि मसाला का मतलब है 'मसाले', लेकिन यह जरूरी नहीं कि तीखा और मसालेदार हो - यह नाम इसके अवयवों के गर्म स्वाद को दर्शाता है, जैसे कि दालचीनी और जीरा। गरम मसाला तब बेहतर होता है जब इसे भुने और पीसे हुए साबुत मसालों से बनाया जाता है, लेकिन यह एक त्वरित और आसान विकल्प है जो काफी अच्छा है। गरम मसाले को एक एयरटाइट कंटेनर में एक ठंडी, सूखी, अंधेरी जगह (जैसे कि पेंट्री या मसाला कैबिनेट) में छह महीने तक स्टोर करें।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
item:"/garammasala",
id:20
},
     {imgsrc:"img/i24.avif",
            alt:"image",
            name:"CHHOLE MASALA POUCH",
            dec: "Chole Masala is a popular Punjabi dish where chickpeas are simmered in a spicy & tangy gravy. 'Chole' is a Punjabi word for 'chickpeas' and 'masala' is a word for 'spices'. So this dish is nothing but chickpeas cooked to perfection with various spices, onions, tomatoes and herbs.",
            ingredients:
              "Chickpea is a legume that is relished all over India. While the term Chole is specifically used to mention the Punjabi dish. Ingredients like anardana (dried pomegranate seeds), amchur (dried mango powder), ajwain (carom seeds), dried red chilies and black cardamoms are the key ingredients in this Chole Masala.",
            packsize:"Available: 10gm | 100gm",
            categ:"grounded",
            hindi:"छोले मसाला एक लोकप्रिय पंजाबी व्यंजन है जिसमें छोले को मसालेदार और तीखी ग्रेवी में पकाया जाता है। छोले पंजाबी शब्द है जिसका मतलब है 'छोले' और 'मसाला' शब्द है जिसका मतलब है 'मसालों'। तो यह व्यंजन कुछ और नहीं बल्कि छोले हैं जिन्हें विभिन्न मसालों, प्याज़, टमाटर और जड़ी-बूटियों के साथ बेहतरीन तरीके से पकाया जाता है। चना एक ऐसी फली है जिसे पूरे भारत में बहुत पसंद किया जाता है। जबकि छोले शब्द का इस्तेमाल खास तौर पर पंजाबी व्यंजन के लिए किया जाता है। अनारदाना (सूखे अनार के बीज), आमचूर (सूखे आम का पाउडर), अजवाइन, सूखी लाल मिर्च और काली इलायची जैसी सामग्री इस छोले मसाले में मुख्य सामग्री हैं।",
            hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
            hindipackpouch:"उपलब्ध पाउच: 10 ग्राम | 100 ग्राम",
item:"/chhole",
            id:101
            },
            
{imgsrc:"img/i22.avif",
alt:"image",
name:"MEAT MASALA POUCH",
dec: "Hiven Meat Masala adds an exotic taste as well as aroma to varying types of meat prepartions and excellent in quality. Our Meat Masala is a blend of various ingredients.Cooking Instructions:3 Easy Step Recipe:Step 1: TO Cook 500gms of meat cut 150gms onion, grind 30 gm garlic 20 gm ginger and make a paste with 15 g Hiven Meat Masala.Step 2: Fry onion with 70g oil till it turns golden brown. Than mix Hiven Meat Masala and ginger garlic paste and fry it upto 10 minutes.Step 3: After that mix the meat and fry it again for 15 minutes, then add salt and water as desired and let it cook 5-10 minutes in cooker.",
    ingredients:
      "Turmeric Powder, Coriander Powder, chilli Powder, Black pepper Powder, Cardamom, Cumin seed, Dry Ginger, Cassia, Onion Powder, Garlic, Nutmeg, cloves, Mace, Poppy seeds.",
packsize:"Available: 10gm | 100gm",
categ:"grounded",
hindi:"हिवेन मीट मसाला विभिन्न प्रकार के मीट तैयारियों में एक अनोखा स्वाद और सुगंध जोड़ता है और इसकी गुणवत्ता भी बेहतरीन है। हमारा मीट मसाला विभिन्न सामग्रियों का मिश्रण है। खाना पकाने के निर्देश: 3 आसान स्टेप रेसिपी: चरण 1: 500 ग्राम मीट पकाने के लिए 150 ग्राम प्याज काटें, 30 ग्राम लहसुन 20 ग्राम अदरक पीसें और 15 ग्राम हिवेन मीट मसाला के साथ पेस्ट बनाएं। चरण 2: प्याज को 70 ग्राम तेल में सुनहरा भूरा होने तक भूनें। फिर हिवेन मीट मसाला और अदरक लहसुन का पेस्ट मिलाएं और इसे 10 मिनट तक भूनें। चरण 3: उसके बाद मीट को मिलाएं और इसे फिर से 15 मिनट तक भूनें, फिर नमक और पानी डालें और इसे कुकर में 5-10 मिनट तक पकने दें।",
hindipack:"उपलब्ध: 50 ग्राम | 100 ग्राम.",
hindipackpouch:"उपलब्ध पाउच: 10 ग्राम | 100 ग्राम",
item:"/meatmasala",
id:21
}

]

export {Ground};